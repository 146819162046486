
  import { Component, Vue } from 'vue-property-decorator'
  import { namespace } from 'vuex-class'
  import { UserInterface, UserInfoInterface } from '@/interface/BaseInterface'
  import { MODE } from '@/config'

  const authStore = namespace('tAuth')

  @Component
  export default class Login extends Vue {
    @authStore.Action('login') login!: (data: UserInterface) => Promise<any>
    @authStore.Action('updateUserInfo') updateUserInfo!: (user: UserInfoInterface) => void
    @authStore.Getter('isLogin') isLogin!: boolean

    mode = MODE

    ruleForm: UserInterface = {
      username: '',
      password: ''
    }

    rules = {
      username: [{ required: true, trigger: 'blur' }],
      password: [{ required: true, trigger: 'blur' }]
    }

    isLoading = false

    handleLogin() {
      //@ts-ignore
      this.$refs.ruleForm.validate(async (valid: boolean) => {
        try {
          if (valid) {
            if (this.isLoading) {
              return
            }
            this.isLoading = true
            const result = await this.login(this.ruleForm)
            this.updateUserInfo(result.user)
            this.$router.push({ name: 'home' })
            this.isLoading = false
          }
        } catch (error) {
          this.isLoading = false
          this.$message.error(error.message)
        }
      })
    }

    async handleDevLogin() {
      try {
        const result = await this.login({
          username: 'admin',
          password: '@123456#'
        })
        this.updateUserInfo(result.user)
        this.$router.push({ name: 'home' })
      } catch (error) {
        console.log(1)
      }
    }

    mounted() {
      // if (this.isLogin) {
      //   this.$router.push({ name: 'home' })
      // }
    }
  }
